<template>
  <input v-if="hasEtc"
         type="text" placeholder="기타사항을 입력해주세요" v-model="etcValue" @input="onInput">
</template>

<script>
  export default {
    name: "CInputEtc",
    props: {
      value: {
        required: true
      },
      etc: {
        type: String
      }
    },
    created() {
      this.etcValue = this.etc;
    },
    data() {
      return {
        etcValue: ''
      }
    },
    computed: {
      hasEtc() {
        return (typeof(this.value) == 'string' && this.value === '기타') ||
          (Array.isArray(this.value) && this.value.some(i => i === '기타'));
      }
    },
    methods: {
      onInput(e) {
        this.etcValue = e.target.value;
        this.$emit('update:etc', this.etcValue);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  input::placeholder
    color #BDBDBD
  input
    font-size 13px
    padding 12px 16px
    border-radius 8px
    width 100%
    border 1px solid $main
    color $main
    background-color white
</style>
